import React from "react";

import { MdEmail } from "react-icons/md";
import { IoLogoFacebook } from "react-icons/io5";
import {
  IoLogoInstagram,
  IoIosGlobe,
  IoIosCall,
  IoMdClose,
} from "react-icons/io";
import { GoPencil } from "react-icons/go";
import { Link } from "react-router-dom";

const MacOSWindow = ({
  title,
  cover,
  avatar,
  name,
  location,
  description,
  facebook,
  instagram,
  website,
  email,
  phone,
  terms,
  onUpdateCover,
  onUpdateAvatar,
}) => (
  <div className="bg-[#231E27] w-full mx-auto mt-4 md:mt-10 shadow-lg rounded-xl overflow-hidden">
    <div className="bg-[#38353c] flex items-center justify-start p-4">
      {/* Window controls */}
      <div className="flex space-x-2">
        <button className="h-3 w-3 bg-red-500 rounded-full focus:outline-none"></button>
        <button className="h-3 w-3 bg-yellow-500 rounded-full focus:outline-none"></button>
        <button className="h-3 w-3 bg-green-500 rounded-full focus:outline-none"></button>
      </div>
      <div className="flex-grow text-center text-white pr-8 font-medium">
        {title}
      </div>
    </div>
    <div className="">
      <label htmlFor="cover-upload" className="relative cursor-pointer block">
        <img
          className="w-full h-[200px] relative object-cover"
          alt={name ?? ""}
          src={cover}
        />
        {onUpdateCover ? (
          <>
            <div className="bg-opalBlue absolute p-2 rounded-full justify-center items-center flex top-2 right-2">
              <GoPencil color="white" />
            </div>
            <input
              type="file"
              id="cover-upload"
              style={{ display: "none" }}
              accept="image/*"
              onChange={onUpdateCover}
            />
          </>
        ) : null}
      </label>

      <label
        htmlFor="avatar-upload"
        className="relative w-fit cursor-pointer block z-10"
      >
        <img
          src={avatar}
          className="mt-[-48px] h-24 w-24 rounded-full border-white mx-4 object-cover"
          alt={name ?? ""}
        />
        {onUpdateAvatar ? (
          <>
            <div className="bg-opalBlue absolute p-2 rounded-full justify-center items-center flex bottom-0 right-2">
              <GoPencil color="white" />
            </div>
            <input
              type="file"
              id="avatar-upload"
              style={{ display: "none" }}
              accept="image/*"
              onChange={onUpdateAvatar}
            />
          </>
        ) : null}
      </label>

      <div className="px-6 pt-3 pb-6">
        <h1 className="text-xl text-white font-bold mb-1">{name ?? ""}</h1>
        <h4 className="font-normal text-white text-sm md:text-base">
          {location ?? ""}
        </h4>

        <div className="flex flex-row mt-1 mb-3">
          {phone ? (
            <div className="flex flex-row items-center justify-center">
              <IoIosCall color="white" />
              <h4 className="ml-1 font-normal text-white text-sm md:text-base">
                {phone ?? ""}
              </h4>
            </div>
          ) : null}

          <div style={{ paddingLeft: 24 }} />

          {email ? (
            <div className="flex flex-row items-center justify-center">
              <MdEmail color="white" />
              <h4 className="ml-2 font-normal text-white text-sm md:text-base">
                {email ?? ""}
              </h4>
            </div>
          ) : null}
        </div>

        <h4 className="font-normal text-white text-sm md:text-base text-wrap whitespace-pre">
          {description ?? ""}
        </h4>

        {/* Social Links */}
        <div className="flex flex-row mt-4 flex-wrap">
          {facebook ? (
            <>
              <Link
                to={facebook ?? ""}
                target={"_blank"}
                className="flex flex-row px-4 py-2 rounded-full justify-center items-center cursor-pointer bg-fbBlue hover:bg-opalDarkBlue transition-all"
              >
                <IoLogoFacebook className="text-base md:text-xl text-white" />
                <p className="text-sm md:text-base ml-2 text-white">Facebook</p>
              </Link>
            </>
          ) : null}

          {instagram ? (
            <>
              <Link
                to={instagram ?? ""}
                target={"_blank"}
                className="flex ml-1 md:ml-2 flex-row px-4 py-2 rounded-full justify-center items-center cursor-pointer bg-instaPurple hover:bg-opalDarkBlue transition-all"
              >
                <IoLogoInstagram className="text-base md:text-2xl text-white" />
                <p className="text-sm md:text-base ml-2 text-white">
                  Instagram
                </p>
              </Link>
            </>
          ) : null}

          {website ? (
            <>
              <Link
                to={website ?? ""}
                target={"_blank"}
                className="flex ml-0 md:ml-2 flex-row px-4 py-2 rounded-full justify-center items-center cursor-pointer bg-opalBlue hover:bg-opalDarkBlue transition-all"
              >
                <IoIosGlobe className="text-base md:text-2xl text-white" />
                <p className="text-sm md:text-base ml-2 text-white">Website</p>
              </Link>
            </>
          ) : null}
        </div>

        {terms ? (
          <>
            <p className="text-white font-semibold mt-4">Terms & Conditions</p>
            <h4 className="font-normal text-white text-sm md:text-base text-wrap whitespace-pre">
              {terms ?? ""}
            </h4>
          </>
        ) : null}
      </div>
    </div>
  </div>
);

export default MacOSWindow;
