import React, { useContext, useEffect, useState } from "react";

import { IoArrowBackOutline } from "react-icons/io5";

import Logo from "../../../assets/images/ic_affiliatesCover.webp";
import { BASE_URL, CREATE_OFFER, UPLOAD_OFFER_COVER } from "../../../ApiRoutes";
import Http from "../../security/Http";
import { toast } from "react-toastify";
import UserProfileContext from "../../../data/UserProfileContext";
import { useNavigate } from "react-router-dom";
import { errorResponse } from "../../Helpers/response";
import axios from "axios";
import MacOSOfferWindow from "../../common/MacOSOfferWindow";

const AddOffer = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(UserProfileContext);

  const [offerCover, setOfferCover] = useState(
    "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/affiliates_cover/ic_affiliates_cover.webp"
  );

  const [offerName, setOfferName] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [availableFrom, setAvailableFrom] = useState("");
  const [availableUntil, setAvailableUntil] = useState("");
  const [rules, setRules] = useState("");
  const [terms, setTerms] = useState("");

  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Uploading File: ", file);
      handleImageUpload(file);
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const userToken = await localStorage.getItem("accessToken");
    console.log("User Token: ", userToken);

    axios
      .post(BASE_URL + UPLOAD_OFFER_COVER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: userToken,
        },
      })
      .then((response) => {
        console.log("File Upload Response: ", response.data);
        // Handle response here
        setOfferCover(response.data.data.file);
        console.log("Image uploaded successfully: ", response.data);
      })
      .catch((error) => {
        console.error("Error uploading image: ", error);
      });
  };

  const onSubmit = async () => {
    if (
      offerName.length <= 0 ||
      discountAmount.length <= 0 ||
      !availableFrom ||
      !availableUntil ||
      rules.length <= 0
    ) {
      toast.error("Please enter all required fields");
      return;
    }

    const postBody = {
      partnerId: profile._id,
      offerName,
      discountAmount,
      availableFrom,
      availableUntil,
      rules,
      terms,
      offerCover,
    };

    console.log("POST BODY: ", postBody);
    const userToken = await localStorage.getItem("accessToken");

    Http.setAuthToken(userToken, navigate);
    Http.callApi(["post", CREATE_OFFER], postBody)
      .then(async (response) => {
        console.log("Offer Response", response.data);

        if (response.data.success == false) {
          toast.error(response.data.message);
          return;
        } else {
          toast.success(`Your offer has been created!`);
          navigate("/offers");
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <div
        className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen pt-4 px-4 md:px-4"
        style={{
          backgroundColor: "#0D0E11",
        }}
      >
        <img
          src={Logo}
          className="flex md:hidden h-auto md:h-[5%] w-full md:w-full object-contain"
          alt="Opal Empire"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <div className="flex flex-col justify-start items-start md:px-16">
            <p className="text-white font-semibold text-2xl md:text-3xl">
              Create an Offer
            </p>

            <p className="text-white text-base md:text-lg mt-3 md:w-2/3">
              Showcase and promote your business to Opal Empire Elite Club
              subscribers by offering specialised discounts, or promotions to
              active subscribers.
            </p>

            {/* Form Fields */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              {/* Business Name */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Discount Name *
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"text"}
                    id="offerName"
                    required={true}
                    value={offerName}
                    onChange={(val) => setOfferName(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Offer Name"
                  />
                </div>
              </div>

              <div className="w-8" />

              {/* Business Location */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Discount Amount *
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"text"}
                    id="discountAmount"
                    required={true}
                    value={discountAmount}
                    onChange={(val) => setDiscountAmount(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Discount Amount"
                  />
                </div>
              </div>
            </div>

            {/* Contact Details */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              {/* Business Name */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Available from *
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"date"}
                    id="availableFrom"
                    required={true}
                    value={availableFrom}
                    onChange={(val) => setAvailableFrom(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Available From"
                  />
                </div>
              </div>

              <div className="w-8" />

              {/* Business Location */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Available Until *
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"date"}
                    id="availableUntil"
                    required={true}
                    value={availableUntil}
                    onChange={(val) => setAvailableUntil(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Available Until"
                  />
                </div>
              </div>
            </div>

            {/* Redemption Rules */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Redemption Rules *
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <textarea
                    type={"text"}
                    style={{
                      minHeight: 64,
                    }}
                    maxLength={250}
                    required={true}
                    id="redemptionRules"
                    value={rules}
                    onChange={(val) => setRules(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Please enter any information neede to redeem your offer (e.g mention 'CODE' when in-store)"
                  />
                </div>
              </div>
            </div>

            {/* Bio & Social Details */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              {/* Business Name */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Terms & Conditions
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <textarea
                    type={"text"}
                    style={{
                      minHeight: 164,
                    }}
                    maxLength={250}
                    id="termsConditions"
                    value={terms}
                    onChange={(val) => setTerms(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Either enter the terms and conditions for this offer, or we will use the terms and conditions you've set on your profile"
                  />
                </div>
              </div>
            </div>

            <p className="text-white text-base md:text-lg mt-4">
              To update the covver image for this offer, simply tap it in the
              Preview window!
            </p>

            <div
              onClick={() => {
                onSubmit();
              }}
              className="cursor-pointer hidden md:flex w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-3"
            >
              <p className="text-white font-medium text-md md:text-lg text-center">
                Create my offer!
              </p>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div className="w-full md:w-1/2">
              <MacOSOfferWindow
                title={!offerName ? "Preview" : offerName}
                cover={offerCover}
                name={offerName}
                amount={discountAmount}
                validFrom={availableFrom}
                validUntil={availableUntil}
                rules={rules}
                terms={terms}
                onUpdateCover={handleCoverChange}
              />
            </div>
          </div>
        </div>

        <button
          className="mt-4 flex md:hidden w-full flex-row justify-center items-center px-6 py-2 font-medium tracking-wide rounded-xl bg-red-800 text-white hover:bg-opalDarkBlue transition-all"
          onClick={() => {
            navigate("/offers");
          }}
        >
          <IoArrowBackOutline color={"white"} className="mr-2" />
          Back
        </button>

        <div
          onClick={() => onSubmit()}
          className="cursor-pointer flex md:hidden w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-4"
        >
          <p className="text-white font-medium text-md md:text-lg text-center">
            Create my offer!
          </p>
        </div>
      </div>
    </>
  );
};

export default AddOffer;
