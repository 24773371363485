import React, { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";

const ApprovedModal = ({ isOpen, onClose }) => {
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsExploding(true);

      setTimeout(() => {
        setIsExploding(false);
      }, 5500);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      {isExploding ? (
        <ConfettiExplosion
          particleCount={512}
          duration={5000}
          zIndex={2550}
          force={0.7}
          width={2048}
          height={"100vh"}
        />
      ) : null}
      <div className="fixed inset-0 z-[2500] overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-xl shadow-xl max-w-lg w-full m-auto relative">
          <img
            className="w-full h-[220px] object-cover rounded-t-xl"
            src={require("../../../assets/images/ic_affiliatesCover.webp")}
          />

          <div className="px-4 py-6">
            <p className="font-medium text-lg">
              Your Business Profile has been approved!
            </p>

            <p className="font-regular text-base mt-2">
              Your Business profile is now visible to all subscribers of the
              Opal Empire Elite Club! Members will now have access to any
              exclusive deals or promotions you offer them on the Opal Empire
              Elite Club Platform.
            </p>

            <button
              className="mt-4 px-4 py-3 w-full font-medium tracking-wide rounded-xl bg-opalBlue text-white hover:bg-opalDarkBlue transition-all"
              onClick={onClose}
            >
              Thanks Opal Gods!
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedModal;
