import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../Pages/Authentication/Login";
import CreateAccount from "../Pages/Authentication/CreateAccount";
import Onboarding from "../Pages/Onboarding/Onboarding";
import Home from "../Pages/Home/Home";
import UpdateProfile from "../Pages/UpdateProfile/UpdateProfile";
import Offers from "../Pages/Offers/Offers";
import AddOffer from "../Pages/AddOffer/AddOffer";
import UpdateTermsConditions from "../Pages/TermsConditions/UpdateTermsConditions";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Login />} />

        <Route path={"/register"} element={<CreateAccount />} />

        <Route path={"/onboarding"} element={<Onboarding />} />

        <Route path={"/home"} element={<Home />} />

        <Route path={"/profile/update"} element={<UpdateProfile />} />

        <Route path={"/offers"} element={<Offers />} />
        <Route path={"/offers/add"} element={<AddOffer />} />

        <Route path={"/terms/update"} element={<UpdateTermsConditions />} />
      </Routes>
    </Router>
  );
};
export default AppRouter;
