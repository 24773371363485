import React, { useContext, useEffect, useState } from "react";
import MacOSWindow from "../../common/MacOSWindow";

import { IoArrowBackOutline } from "react-icons/io5";

import Logo from "../../../assets/images/ic_affiliatesCover.webp";
import {
  BASE_URL,
  GET_PROFILE,
  ONBOARD_PARTNER,
  UPDATE_PROFILE,
  UPDATE_PROFILE_TERMS,
  UPLOAD_AVATAR,
  UPLOAD_COVER,
} from "../../../ApiRoutes";
import Http from "../../security/Http";
import { toast } from "react-toastify";
import UserProfileContext from "../../../data/UserProfileContext";
import { useNavigate } from "react-router-dom";
import { errorResponse } from "../../Helpers/response";
import axios from "axios";

const UpdateTermsConditions = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(UserProfileContext);

  const [businessCover, setBusinessCover] = useState(
    "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/affiliates_cover/ic_affiliates_cover.webp"
  );
  const [businessAvatar, setBusinessAvatar] = useState(
    "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/affiliates_icons/ic_elite_default.webp"
  );
  const [businessName, setBusinessName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessBio, setBusinessBio] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");

  const [terms, setTerms] = useState("");

  useEffect(() => {
    getBusinessProfile();
  }, []);

  const getBusinessProfile = async () => {
    const userToken = await localStorage.getItem("accessToken");
    Http.setAuthToken(userToken, navigate);
    Http.callApi(["get", GET_PROFILE])
      .then(async (response) => {
        console.log("Profile Response", response.data);

        if (response.data.success == false) {
          toast.error(response.data.message);
          return;
        } else {
          const business = response.data.data;
          setBusinessName(business?.name ?? "");
          setBusinessLocation(business?.address ?? "");
          setBusinessPhone(business?.phone ?? "");
          setBusinessEmail(business?.email ?? "");
          setBusinessBio(business?.description ?? "");
          setFacebookUrl(business?.facebookLink ?? "");
          setInstagramUrl(business?.instagramLink ?? "");
          setWebsiteUrl(business?.websiteLink ?? "");
          setBusinessAvatar(business?.logo ?? "");
          setBusinessCover(business?.coverPhoto ?? "");
          setTerms(business?.termsConditions ?? "");
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const onSubmit = async () => {
    const postBody = {
      terms,
    };

    console.log("POST BODY: ", postBody);
    const userToken = await localStorage.getItem("accessToken");

    Http.setAuthToken(userToken, navigate);
    Http.callApi(["post", UPDATE_PROFILE_TERMS], postBody)
      .then(async (response) => {
        console.log("Onboarding Response", response.data);

        if (response.data.success == false) {
          toast.error(response.data.message);
          return;
        } else {
          setProfile(response.data.data);
          toast.success(`Your terms & conditions have been updated!`);
          navigate("/home");
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <div
        className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen pt-4 px-4 md:px-4"
        style={{
          backgroundColor: "#0D0E11",
        }}
      >
        <img
          src={Logo}
          className="flex md:hidden h-auto md:h-[5%] w-full md:w-full object-contain"
          alt="Opal Empire"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <div className="flex flex-col justify-start items-start md:px-16">
            <p className="text-white font-semibold text-2xl md:text-3xl">
              Update your Terms & Conditions
            </p>

            <p className="text-white text-base md:text-base mt-3 md:w-full">
              In order to stay compliant with the Opal Empire Business
              Affiliates Terms & Conditions, you must upload your own set of
              Terms & Conditions. These Terms & Conditions are shown when you
              don't upload Offer-Specific Terms & Conditions.
            </p>

            {/* Bio & Social Details */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              {/* Business Name */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Terms & Conditions
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <textarea
                    type={"text"}
                    style={{
                      minHeight: 164,
                    }}
                    maxLength={250}
                    id="termsConditions"
                    required={true}
                    value={terms}
                    onChange={(val) => setTerms(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Please enter your businesses terms & conditions."
                  />
                </div>
              </div>
            </div>

            <p className="text-white text-base md:text-base mt-4">
              To update your Business Profile, please tap Update Business
              Profile from the Control Panel's Profile Actions.
            </p>

            <button
              className="mt-4 hidden md:flex w-full flex-row justify-center items-center px-6 py-3 font-medium tracking-wide rounded-xl bg-red-800 text-white hover:bg-opalDarkBlue transition-all"
              onClick={() => {
                navigate("/home");
              }}
            >
              <IoArrowBackOutline color={"white"} className="mr-2" />
              Back
            </button>

            <div
              onClick={() => {
                onSubmit();
              }}
              className="cursor-pointer hidden md:flex w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-3"
            >
              <p className="text-white font-medium text-md md:text-lg text-center">
                Update my Terms & Conditions!
              </p>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div className="w-full md:w-1/2">
              <MacOSWindow
                title={!businessName ? "Preview" : businessName}
                cover={businessCover}
                avatar={businessAvatar}
                name={businessName}
                location={businessLocation}
                description={businessBio}
                phone={businessPhone}
                email={businessEmail}
                facebook={facebookUrl}
                instagram={instagramUrl}
                website={websiteUrl}
                terms={terms}
              />
            </div>
          </div>
        </div>

        <button
          className="mt-4 flex md:hidden w-full flex-row justify-center items-center px-6 py-2 font-medium tracking-wide rounded-xl bg-red-800 text-white hover:bg-opalDarkBlue transition-all"
          onClick={() => {
            navigate("/home");
          }}
        >
          <IoArrowBackOutline color={"white"} className="mr-2" />
          Back
        </button>

        <div
          onClick={() => onSubmit()}
          className="cursor-pointer flex md:hidden w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-4"
        >
          <p className="text-white font-medium text-md md:text-lg text-center">
            Update my Terms & Conditions!
          </p>
        </div>
      </div>
    </>
  );
};

export default UpdateTermsConditions;
