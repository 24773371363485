import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

import { IoChevronForwardOutline } from "react-icons/io5";
import { IoArrowBackOutline } from "react-icons/io5";

import Logo from "../../../assets/images/ic_affiliatesCover.webp";
import CommonLoader from "../../AppLoaders/CommonLoader";
import UserProfileContext from "../../../data/UserProfileContext";
import { useNavigate } from "react-router-dom";
import Http from "../../security/Http";
import { GET_OFFERS } from "../../../ApiRoutes";
import { toast } from "react-toastify";
import MacOSOfferWindow from "../../common/MacOSOfferWindow";

const Offers = () => {
  const navigate = useNavigate();
  const { profile } = useContext(UserProfileContext);

  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBusinessOffers();
  }, []);

  const getBusinessOffers = async () => {
    const userToken = await localStorage.getItem("accessToken");

    Http.setAuthToken(userToken, navigate);
    Http.callApi(["get", GET_OFFERS]).then(async (response) => {
      console.log("Offers Response: ", response.data);

      if (response.data.success == false) {
        toast.error(response?.data?.message ?? "");
        navigate("/");
      } else {
        setOffers(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 750);
      }
    });
  };

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`My Offers | Business Affiliates | Control Panel | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link
              rel="canonical"
              href={`https://affiliates.opalempire.com.au/`}
            />
            <meta
              property="og:title"
              content={`My Offers | Business Affiliates | Control Panel | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta
              property="og:url"
              content={`https://affiliates.opalempire.com.au/`}
            />
            <meta
              property="og:image"
              content={
                "https://affiliates.opalempire.com.au/ic_affiliates_cover.webp"
              }
            />
          </Helmet>
        </div>
      </HelmetProvider>

      {!isLoading ? (
        <div
          className="flex flex-col overflow-x-hidden items-start justify-start min-h-svh overflow-hidden w-screen py-16 px-4"
          style={{
            backgroundColor: "#0D0E11",
          }}
        >
          <img
            src={Logo}
            className="flex md:hidden h-auto md:h-[5%] w-full md:w-full object-contain"
            alt="Opal Empire"
          />

          <div className="hidden md:flex flex-row w-full px-2 md:px-16">
            <button
              className="mt-4 flex flex-row justify-center items-center px-6 py-2 font-medium tracking-wide rounded-xl bg-opalBlue text-white hover:bg-opalDarkBlue transition-all"
              onClick={() => {
                navigate("/home");
              }}
            >
              <IoArrowBackOutline color={"white"} className="mr-2" />
              Back
            </button>

            <div style={{ flex: 1 }} />
            <div className="flex flex-col justify-center items-center">
              <p className="text-white font-semibold text-2xl md:text-xl">
                Elite Club Offers
              </p>
              <p className="text-white font-semibold mt-2 text-2xl md:text-base">
                {profile?.name ?? ""}
              </p>
            </div>

            <div style={{ flex: 1 }} />

            <button
              className="mt-4 px-8 py-2 font-medium tracking-wide rounded-xl bg-opalBlue text-white hover:bg-opalDarkBlue transition-all"
              onClick={() => {
                navigate("/offers/add");
              }}
            >
              Create an Offer
            </button>
          </div>

          <div className="flex md:hidden flex-col w-full px-2 md:px-16">
            <div className="flex flex-col justify-start items-start">
              <p className="text-white font-semibold text-2xl md:text-xl">
                Elite Club Offers
              </p>
            </div>
            <button
              className="mt-4 flex flex-row justify-center items-center px-6 py-2 font-medium tracking-wide rounded-xl bg-opalBlue text-white hover:bg-opalDarkBlue transition-all"
              onClick={() => {
                navigate("/home");
              }}
            >
              <IoArrowBackOutline color={"white"} className="mr-2" />
              Back
            </button>

            <div style={{ flex: 1 }} />

            <button
              className="mt-4 px-8 py-2 font-medium tracking-wide rounded-xl bg-opalBlue text-white hover:bg-opalDarkBlue transition-all"
              onClick={() => {
                navigate("/offers/add");
              }}
            >
              Create an Offer
            </button>
          </div>

          <div className="flex flex-col justify-start items-start px-2 md:px-16 mt-8">
            {/* Offers */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {offers && offers?.length > 0 ? (
                offers.map((item) => {
                  return (
                    <>
                      <MacOSOfferWindow
                        title={item?.name ?? ""}
                        active={item?.active}
                        cover={
                          item?.image ??
                          "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/affiliates_cover/ic_affiliates_cover.webp"
                        }
                        name={item?.name ?? ""}
                        amount={item?.description ?? ""}
                        validFrom={item?.validFrom ?? ""}
                        validUntil={item?.validUntil ?? ""}
                        rules={item?.redemptionRules ?? ""}
                        terms={item?.conditions ?? ""}
                      />
                    </>
                  );
                })
              ) : (
                <>
                  <p className="text-white">
                    You have not uploaded any offers as of yet.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen px-4"
            style={{
              backgroundColor: "#0D0E11",
            }}
          >
            <CommonLoader />
            <p className="text-white font-semibold">Updating Offers</p>
          </div>
        </>
      )}
    </>
  );
};

export default Offers;
