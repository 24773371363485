import React, { useContext, useEffect } from "react";
import AppRouter from "./Components/AppRouter/AppRouter";

import "./App.css";

const App = () => {
  return <AppRouter />;
};

export default App;
