import React from "react";

import { FaRegClock } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import StatusDot from "./StatusDot";

const MacOSOfferWindow = ({
  title,
  cover,
  active,
  name,
  amount,
  validFrom,
  validUntil,
  rules,
  terms,
  onUpdateCover,
}) => {
  const from = new Date(validFrom ?? new Date().toString());
  const until = new Date(validUntil ?? new Date().toString());
  return (
    <>
      <div className="bg-[#231E27] w-full mx-auto mt-4 md:mt-10 shadow-lg rounded-xl overflow-hidden">
        <div className="bg-[#38353c] flex items-center justify-start p-4">
          {/* Window controls */}
          <div className="flex space-x-2">
            <button className="h-3 w-3 bg-red-500 rounded-full focus:outline-none"></button>
            <button className="h-3 w-3 bg-yellow-500 rounded-full focus:outline-none"></button>
            <button className="h-3 w-3 bg-green-500 rounded-full focus:outline-none"></button>
          </div>
          <div className="flex-grow text-center text-white pr-8 font-medium">
            {title}
          </div>
        </div>

        <div className="">
          <label
            htmlFor="cover-upload"
            className="relative cursor-pointer block"
          >
            <img
              className="w-full h-[200px] relative object-cover"
              alt={name ?? ""}
              src={cover}
            />
            {onUpdateCover ? (
              <>
                <div className="bg-opalBlue absolute p-2 rounded-full justify-center items-center flex top-2 right-2">
                  <GoPencil color="white" />
                </div>
                <input
                  type="file"
                  id="cover-upload"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={onUpdateCover}
                />
              </>
            ) : null}
          </label>

          <div className="p-6">
            <h1 className="text-xl text-white font-bold mb-1">{name ?? ""}</h1>

            <div className="mt-1 flex flex-row justify-start items-center">
              <StatusDot status={active ? "success" : "idle"} />
              <p className="text-white font-medium text-base md:text-base ml-3">
                {!active ? "Unavailable" : "Available"}
              </p>
            </div>

            <h4 className="font-normal text-white text-sm md:text-base">
              {amount ?? ""}
            </h4>

            <div className="flex flex-row mt-3">
              {validFrom ? (
                <div className="flex flex-col">
                  <p className="text-white font-semibold">Available From</p>
                  <div className="flex flex-row mt-2">
                    <FaRegClock color={"white"} className="mt-[3px]" />
                    <p className="text-white font-semibold text-base ml-2">
                      {from.toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ) : null}

              <div className="px-8" />

              {validUntil ? (
                <div className="flex flex-col">
                  <p className="text-white font-semibold">Available Until</p>
                  <div className="flex flex-row mt-2">
                    <FaRegClock color={"white"} className="mt-[3px]" />
                    <p className="text-white font-semibold text-base ml-2">
                      {until.toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>

            {rules ? (
              <>
                <p className="text-white font-semibold mt-4">
                  Redemption Rules
                </p>
                <h4 className="font-normal text-white text-sm md:text-base text-wrap whitespace-pre">
                  {rules ?? ""}
                </h4>
              </>
            ) : null}

            {terms ? (
              <>
                <p className="text-white font-semibold mt-4">
                  Offer Terms & Conditions
                </p>
                <h4 className="font-normal text-white text-sm md:text-base text-wrap whitespace-pre">
                  {terms ?? ""}
                </h4>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MacOSOfferWindow;
