import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

import Logo from "../../../assets/images/ic_affiliatesCover.webp";
import Desktop from "../../../assets/images/elite_edge.webp";
import MacOSWindow from "../../common/MacOSWindow";
import UserProfileContext from "../../../data/UserProfileContext";
import timeOfDay from "../../common/TimeGreeting";
import StatusDot from "../../common/StatusDot";

import { IoChevronForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../AppLoaders/CommonLoader";
import Http from "../../security/Http";
import { GET_PROFILE } from "../../../ApiRoutes";
import { errorResponse } from "../../Helpers/response";
import { toast } from "react-toastify";
import ApprovedModal from "../../common/Modals/AffiliateModal";

const Home = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(UserProfileContext);

  const [loading, setLoading] = useState(true);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getBusinessProfile();
  }, []);

  const getBusinessProfile = async () => {
    const userToken = await localStorage.getItem("accessToken");

    Http.setAuthToken(userToken, navigate);
    Http.callApi(["get", GET_PROFILE])
      .then(async (response) => {
        console.log("Profile Response", response.data);

        if (response.data.success == false) {
          toast.error(response.data.message);
          return;
        } else {
          setProfile(response.data.data);

          let profileStatus = await localStorage.getItem("profileStatus");

          // Convert profileStatus from string to boolean
          profileStatus = profileStatus === "true";

          // If profileStatus doesn't exist, initialize it as false in localStorage
          if (profileStatus === null) {
            await localStorage.setItem("profileStatus", "false");
            profileStatus = false;
          }

          console.log("Profile Status: ", profileStatus);
          console.log("Profile Active: ", response.data.data.active);

          // Logic to determine if the modal should be shown
          if (!profileStatus && response.data.data.active) {
            setModalVisible(true); // Assuming setModalVisible is a function to show the modal
            await localStorage.setItem("profileStatus", "true"); // Update the profileStatus in localStorage
          }

          console.log("Profile Status: ", profileStatus);
          console.log("Profile Active: ", response.data.data.active);

          setTimeout(() => {
            setLoading(false);
          }, 750);
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Business Affiliates | Control Panel | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link
              rel="canonical"
              href={`https://affiliates.opalempire.com.au/`}
            />
            <meta
              property="og:title"
              content={`Business Affiliates | Control Panel | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta
              property="og:url"
              content={`https://affiliates.opalempire.com.au/`}
            />
            <meta
              property="og:image"
              content={
                "https://affiliates.opalempire.com.au/ic_affiliates_cover.webp"
              }
            />
          </Helmet>
        </div>
      </HelmetProvider>

      <ApprovedModal
        isOpen={modalVisible}
        onClose={() => setModalVisible(!modalVisible)}
      />

      {!loading ? (
        <div
          className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen px-4"
          style={{
            backgroundColor: "#0D0E11",
          }}
        >
          <img
            src={Logo}
            className="flex md:hidden h-auto md:h-[5%] w-full md:w-full object-contain"
            alt="Opal Empire"
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <div className="flex flex-col justify-start items-start px-2 md:px-16">
              <img
                src={Desktop}
                className="hidden md:flex h-auto md:h-28 w-full md:w-auto object-contain mb-6"
                alt="Opal Empire"
              />
              <p className="text-white font-medium text-xl md:text-xl">
                {timeOfDay()} {profile?.userId?.first_name ?? ""}
              </p>
              <p className="text-white font-semibold text-2xl md:text-2xl">
                {profile?.name ?? ""}
              </p>

              {/* Profile Status Indicator */}
              <div
                className="mt-1"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StatusDot status={profile?.active ? "success" : "idle"} />
                <p className="text-white font-medium text-base md:text-base ml-3">
                  {!profile?.active
                    ? "Business Awaiting Verification"
                    : "Business Active & Visible"}
                </p>
              </div>

              {/* Profile Actions */}

              <div className="mt-8 w-full flex flex-col justify-start items-start">
                <p className="text-white font-semibold mt-4 mb-3">
                  Profile Actions
                </p>
                <div
                  onClick={() => {
                    navigate("/offers");
                  }}
                  className="bg-transparent hover:bg-white border border-grey-300 hover:border-white text-white hover:text-black flex flex-row items-center justify-center p-4 rounded-lg w-full md:w-2/3 cursor-pointer"
                >
                  <img
                    style={{ width: 42, height: 42, objectFit: "contain" }}
                    src={require("../../../assets/images/ic_offers.webp")}
                  />
                  <p className="font-medium ml-4">My Offers</p>
                  <div style={{ flex: 1 }} />

                  <IoChevronForwardOutline />
                </div>

                <div
                  onClick={() => {
                    navigate("/profile/update");
                  }}
                  className="bg-transparent hover:bg-white border border-grey-300 hover:border-white text-white hover:text-black flex flex-row items-center justify-center p-4 rounded-lg w-full md:w-2/3 mt-4 cursor-pointer"
                >
                  <img
                    style={{ width: 42, height: 42, objectFit: "contain" }}
                    src={require("../../../assets/images/ic_shop.webp")}
                  />
                  <p className="font-medium ml-4">Update Business Profile</p>
                  <div style={{ flex: 1 }} />

                  <IoChevronForwardOutline />
                </div>

                <div
                  onClick={() => {
                    navigate("/terms/update");
                  }}
                  className="bg-transparent hover:bg-white border border-grey-300 hover:border-white text-white hover:text-black flex flex-row items-center justify-center p-4 rounded-lg w-full md:w-2/3 mt-4 cursor-pointer"
                >
                  <img
                    style={{ width: 42, height: 42, objectFit: "contain" }}
                    src={require("../../../assets/images/ic_doc.webp")}
                  />
                  <p className="font-medium ml-4">Update Terms & Conditions</p>
                  <div style={{ flex: 1 }} />

                  <IoChevronForwardOutline />
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="w-full md:w-1/2 pb-16 md:pb-0">
                <MacOSWindow
                  title={profile?.name ?? ""}
                  cover={profile?.coverPhoto ?? ""}
                  avatar={profile?.logo ?? ""}
                  name={profile?.name ?? ""}
                  location={profile?.address ?? ""}
                  description={profile?.description ?? ""}
                  phone={profile?.phone ?? ""}
                  email={profile?.email ?? ""}
                  facebook={profile?.facebookLink ?? ""}
                  instagram={profile?.instagramLink ?? ""}
                  website={profile?.websiteLink ?? ""}
                  terms={profile?.termsConditions ?? ""}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen px-4"
            style={{
              backgroundColor: "#0D0E11",
            }}
          >
            <CommonLoader />
            <p className="text-white font-semibold">Updating Profile</p>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
