import React, { useContext, useEffect, useState } from "react";
import MacOSWindow from "../../common/MacOSWindow";

import { IoLogoFacebook } from "react-icons/io5";
import {
  IoLogoInstagram,
  IoIosGlobe,
  IoIosCall,
  IoMdClose,
} from "react-icons/io";

import Logo from "../../../assets/images/ic_affiliatesCover.webp";
import {
  BASE_URL,
  GET_PROFILE,
  ONBOARD_PARTNER,
  UPDATE_PROFILE,
  UPLOAD_AVATAR,
  UPLOAD_COVER,
} from "../../../ApiRoutes";
import Http from "../../security/Http";
import { toast } from "react-toastify";
import UserProfileContext from "../../../data/UserProfileContext";
import { useNavigate } from "react-router-dom";
import { errorResponse } from "../../Helpers/response";
import axios from "axios";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(UserProfileContext);

  const [businessCover, setBusinessCover] = useState(
    "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/affiliates_cover/ic_affiliates_cover.webp"
  );
  const [businessAvatar, setBusinessAvatar] = useState(
    "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/affiliates_icons/ic_elite_default.webp"
  );
  const [businessName, setBusinessName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessBio, setBusinessBio] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");

  useEffect(() => {
    getBusinessProfile();
  }, []);

  const getBusinessProfile = async () => {
    const userToken = await localStorage.getItem("accessToken");
    Http.setAuthToken(userToken, navigate);
    Http.callApi(["get", GET_PROFILE])
      .then(async (response) => {
        console.log("Profile Response", response.data);

        if (response.data.success == false) {
          toast.error(response.data.message);
          return;
        } else {
          const business = response.data.data;
          setBusinessName(business?.name ?? "");
          setBusinessLocation(business?.address ?? "");
          setBusinessPhone(business?.phone ?? "");
          setBusinessEmail(business?.email ?? "");
          setBusinessBio(business?.description ?? "");
          setFacebookUrl(business?.facebookLink ?? "");
          setInstagramUrl(business?.instagramLink ?? "");
          setWebsiteUrl(business?.websiteLink ?? "");
          setBusinessAvatar(business?.logo ?? "");
          setBusinessCover(business?.coverPhoto ?? "");
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Uploading File: ", file);
      handleImageUpload(file, "cover");
    }
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Uploading File: ", file);
      handleImageUpload(file, "avatar");
    }
  };

  const handleImageUpload = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);

    const userToken = await localStorage.getItem("accessToken");
    console.log("User Token: ", userToken);

    axios
      .post(
        type === "cover" ? BASE_URL + UPLOAD_COVER : BASE_URL + UPLOAD_AVATAR,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: userToken,
          },
        }
      )
      .then((response) => {
        console.log("File Upload Response: ", response.data);
        // Handle response here
        if (type === "cover") {
          setBusinessCover(response.data.data.file);
        } else {
          setBusinessAvatar(response.data.data.file);
        }
        console.log("Image uploaded successfully: ", response.data);
      })
      .catch((error) => {
        console.error("Error uploading image: ", error);
      });
  };

  const onSubmit = async () => {
    const postBody = {
      businessCover,
      businessAvatar,
      businessName,
      businessLocation,
      businessPhone,
      businessEmail,
      businessBio,
      facebookUrl,
      instagramUrl,
      websiteUrl,
    };

    console.log("POST BODY: ", postBody);
    const userToken = await localStorage.getItem("accessToken");

    Http.setAuthToken(userToken, navigate);
    Http.callApi(["post", UPDATE_PROFILE], postBody)
      .then(async (response) => {
        console.log("Onboarding Response", response.data);

        if (response.data.success == false) {
          toast.error(response.data.message);
          return;
        } else {
          setProfile(response.data.data);
          toast.success(`Your profile has been updated!`);
          navigate("/home");
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <div
        className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen pt-4 px-4 md:px-4"
        style={{
          backgroundColor: "#0D0E11",
        }}
      >
        <img
          src={Logo}
          className="flex md:hidden h-auto md:h-[5%] w-full md:w-full object-contain"
          alt="Opal Empire"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <div className="flex flex-col justify-start items-start md:px-16">
            <p className="text-white font-semibold text-2xl md:text-3xl">
              Update your Business Profile
            </p>

            <p className="text-white text-base md:text-lg mt-3 md:w-2/3">
              As you update the details below, the preview window will update to
              show you a live look at how Opal Empire Elite Club users will see
              your profile.
            </p>

            {/* Form Fields */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              {/* Business Name */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Business Name
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"text"}
                    id="businessName"
                    value={businessName}
                    onChange={(val) => setBusinessName(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Business Name"
                  />
                </div>
              </div>

              <div className="w-8" />

              {/* Business Location */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Business Location
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"text"}
                    id="businessLocation"
                    value={businessLocation}
                    onChange={(val) => setBusinessLocation(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Business Location"
                  />
                </div>
              </div>
            </div>

            {/* Contact Details */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              {/* Business Name */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Business Contact Number
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"number"}
                    id="businessPhone"
                    value={businessPhone}
                    onChange={(val) => setBusinessPhone(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Phone Number"
                  />
                </div>
              </div>

              <div className="w-8" />

              {/* Business Location */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Business Email Address
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"email"}
                    id="businessEmail"
                    value={businessEmail}
                    onChange={(val) => setBusinessEmail(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Email Address"
                  />
                </div>
              </div>
            </div>

            {/* Bio & Social Details */}
            <div className="mt-2 w-full flex flex-col md:flex-row">
              {/* Business Name */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Business Bio
                </p>
                <div className="mt-1 md:mt-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <textarea
                    type={"text"}
                    style={{
                      minHeight: 164,
                    }}
                    maxLength={250}
                    id="businessBio"
                    value={businessBio}
                    onChange={(val) => setBusinessBio(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Enter a short bio about your business"
                  />
                </div>
              </div>

              <div className="w-8" />

              {/* Business Location */}
              <div className="w-full">
                <p className="pt-4 pb-2 text-white font-semibold">
                  Business Social Links
                </p>
                <div className="w-full flex flex-row justify-center items-center mt-1 md:mt-2">
                  <IoLogoFacebook className="text-3xl md:text-4xl text-white" />
                  <div className="ml-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                    <input
                      type={"url"}
                      id="facebookUrl"
                      value={facebookUrl}
                      onChange={(val) => setFacebookUrl(val.target.value)}
                      className="w-full text-md md:text-base"
                      placeholder="Facebook URL"
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row justify-center items-center mt-1 md:mt-2">
                  <IoLogoInstagram className="text-3xl md:text-4xl text-white" />
                  <div className="ml-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                    <input
                      type={"url"}
                      id="instagramUrl"
                      value={instagramUrl}
                      onChange={(val) => setInstagramUrl(val.target.value)}
                      className="w-full text-md md:text-base"
                      placeholder="Instagram URL"
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row justify-center items-center mt-1 md:mt-2">
                  <IoIosGlobe className="text-3xl md:text-4xl text-white" />
                  <div className="ml-2 w-full bg-white border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                    <input
                      type={"url"}
                      id="websiteUrl"
                      value={websiteUrl}
                      onChange={(val) => setWebsiteUrl(val.target.value)}
                      className="w-full text-md md:text-base"
                      placeholder="Website URL"
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className="text-white text-base md:text-lg mt-4">
              To update your profile's cover & avatar images, simply tap them in
              the Preview window!
            </p>

            <div
              onClick={() => {
                onSubmit();
              }}
              className="cursor-pointer hidden md:flex w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-3"
            >
              <p className="text-white font-medium text-md md:text-lg text-center">
                Update my Profile!
              </p>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div className="w-full md:w-1/2">
              <MacOSWindow
                title={!businessName ? "Preview" : businessName}
                cover={businessCover}
                avatar={businessAvatar}
                name={businessName}
                location={businessLocation}
                description={businessBio}
                phone={businessPhone}
                email={businessEmail}
                facebook={facebookUrl}
                instagram={instagramUrl}
                website={websiteUrl}
                onUpdateCover={handleCoverChange}
                onUpdateAvatar={handleAvatarChange}
              />
            </div>
          </div>
        </div>

        <div
          onClick={() => onSubmit()}
          className="cursor-pointer flex md:hidden w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-8"
        >
          <p className="text-white font-medium text-md md:text-lg text-center">
            Update my Profile!
          </p>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
